import { Injectable } from '@angular/core';
import { BiAction, BiLocationObject, BiObjectType } from './interfaces/bi.enums';
import { IUserActionEvent } from './interfaces/bi.events';
import { Iframes } from 'app/rx/enums/Iframes';
import { Router } from '@angular/router';
import { Consts } from '@shared/consts';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { Utils } from '@shared/utils.service';
import { TimberService } from './timber.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseDestroyable } from '@core/base-destroyable';
import { takeUntil } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BiFeatureWrapperService extends BaseDestroyable {
	pagesWithIds: string[] = ['patients'];

	constructor(
		private router: Router,
		private globalSettingsService: GlobalSettingsService,
		private utils: Utils,
		private timberService: TimberService,
		private translateService: TranslateService,
	) {
		super();
	}

	LogButtonClicked(parent: BiLocationObject, buttonName: string, orderId?: number): { userAction: IUserActionEvent } {
		return {
			userAction: {
			locationParentObject: parent,
			objectName: buttonName,
			action: BiAction.Clicked,
			objectType: BiObjectType.Button,
			selectedValue: orderId ? `orderId: ${orderId.toString()}` : null
		}
		};
	}

	logLinkClicked(objectId: string) {
		this.timberService.userActionEvent({
			locationParentObject: this.getCurrentPage(),
			objectName: objectId,
			action: BiAction.Clicked,
			objectType: BiObjectType.Link,
			selectedValue: null,
		});
	}
	
	convertNames(page: Iframes | string): string {
		switch (page) {
			case 'rx':
			case Iframes.Rx:
				return 'RxViewer';
			case 'viewer':
			case Iframes.Viewer:
				return 'WebViewer';
			case Iframes.Exocad3dViewer:
				return 'ExocadViewer';
			case 'orders':
				return 'Orders';
			case 'patients':
				return 'Patients';
			case 'messages':
				return 'Messages';
			case 'learningcenter':
				return 'LearningCenter';
			case 'home':
				return 'Home';
			default:
				return page;
		}
	}

	extractPageFromString(url: string): string {
		const urlArr = url.slice(1)?.split('?')[0].split('/');

		const index = urlArr.findIndex(
			(pathSection) =>
				pathSection != '' && !pathSection.includes(Consts.wrapper) && pathSection != this.globalSettingsService.rolePath()
		);

		const pathSection = urlArr[index];

		if (index + 1 !== urlArr.length) {
			if (
				this.pagesWithIds.includes(pathSection) &&
				(!isNaN(Number(urlArr[index + 1])) || this.utils.guidPattern.test(urlArr[index + 1]))
			) {
				return this.convertNames(this.getChildPage(pathSection));
			}
			if (pathSection === 'settings') {
				return `settings/${urlArr[index + 1]}`;
			}
		}

		return this.convertNames(pathSection);
	}

	getCurrentPage(): string {
		const url = this.router.url;
		return this.extractPageFromString(url);
	}

	getChildPage(parentPage: string): string {
		switch (parentPage) {
			case 'patients':
				return 'PatientsOrders';
			default:
				return parentPage;
		}
	}
	
	CheckIfLinkClick(element: HTMLElement, translationsParentContext) {
		if (element.tagName.toLowerCase() === 'a') {
			const id = element.id != '' ? element.id : element.parentElement.id;
			if(id) {
				this.logLinkClicked(element.id);
			} else if (element.innerText != '') {
				this.translateService.getTranslation(this.translateService.currentLang).pipe(takeUntil(this.componentAlive$))
				.subscribe((translations) => {
					const contextTranslations = translations[translationsParentContext];
					for (const key in contextTranslations) {
						if (contextTranslations[key] === element.innerText) {
							this.logLinkClicked(`${translationsParentContext}-${key}`);
						}
					}
				});
			}
		}
	}
}
