import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable, of } from 'rxjs';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { RouterInterceptorService } from '../../core/routerInterceptor.service';
import { SettingsModal } from '../../settings/settings.component';
import { Utils } from '../utils.service';
import { GlobalSettingsService, ContextParams, GlobalSettings } from '../../core/globalSettings.service';
import { MessagesService } from '../../messages/messages.service';
import { DownloadNotificationService } from '../downloadNotification/downloadNotification.service';
import { LabCasesState, WindowsApplicationProtocol, CountryCode, FeatureToggle } from '../enums';
import { RxService } from '../../rx/services/rx.service';
import { OrdersService, Order } from '../../doctors/orders/orders.service';
import { DownloadLinkModal } from '../../shared/downloadLinkModal/downloadLinkModal.component';
import { WindowApplicationLinkData } from '../../shared/windowsApplicationLinkData';
import { TranslateService } from '@ngx-translate/core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { LabExportModal } from '../labExport/labExport.component';
import { StorageType } from '../../shared/enums';
import * as AT from '../../shared/actionTypes';
import { EupObserver } from '../../core/eupObserver.service';
import { IteroLabService } from '../../shared/iteroLab.service';
import { Company } from '@shared/generalInterfaces';
import { AccessibilityService } from '../accessibility.service';
import { Consts } from '@shared/consts';
import { AuthService } from 'app/services/authentication/auth.service';
import { LocalStorageService } from '@core/localStorage.service';
import { map, filter, tap, take } from 'rxjs/operators';
import { AccountManagementService } from '../../practiceManagement/services/account-management.service';
import { SpinnerService } from '@core/spinner/spinner.service';

@Component({
	selector: 'eup-lab-sticky-header',
	templateUrl: './labStickyHeader.component.html',
	styleUrls: ['./labStickyHeader.component.scss'],
	providers: [IteroLabService]
})
export class LabStickyHeaderComponent implements OnInit, OnDestroy {
	public static readonly homeImage = 'home46';
	public static readonly homeLink = 'home';
	public messagesCount = 0;
	private readonly backImage = 'backButton46';
	private rolePath: string;
	private labCasesState: LabCasesState = undefined;
	readonly storage: Storage;
	@ViewChild(LabExportModal) labExportModal: LabExportModal;
	@Output() public downloadClicked: EventEmitter<any> = new EventEmitter();

	winAppData: WindowApplicationLinkData;
	hideAppButton = true;
	isLogoutOpen = false;
	isLinksMenuOpen = false;
	disableActionButtons = true;
	lastBowId?: number = null;
	lastDetailsId?: number = null;
	faExternalLinkAlt = faExternalLinkAlt;
	orthoCadSetupDownloadUrl: string;
	iTeroSetupDownloadUrl: string;
	orthoCadSetupDownloadUrl_acs: string;
	iTeroSetupDownloadUrl_acs: string;
	isOrthoCase: boolean;

	contextChangedSubscription: Subscription;
	homeLinkClickSubscription: Subscription;
	orderUpdatedEventSubscription: Subscription;
	eupObserverSubscription: Subscription;

	@Input() titleText = '';
	@Input() image = LabStickyHeaderComponent.homeImage;
	@Input() backLink = LabStickyHeaderComponent.homeLink;
	@Input() backToLastPage = null;
	@Input() showAvatarContainer = 'true';
	@Input() showLinksContainer = 'true';
	@Input() showActionButtons = 'false';
	@Input() uponHomeLinkClickCallback: Function;
	@Input() homeLinkClickEvent: EventEmitter<any>;
	@Input() disableSiteNavigation = 'false';
	@Input() isBackLinkAbsoluteUrl = 'false';
	@Input() shouldAddRoleToBackLink = 'true';
	@Input() orderId: number;
	@Input() shortOrderData: Order;
	@Input() disableDownloadScan: boolean;
	@Input() disableiTeroLab: boolean;

	@ViewChild(SettingsModal) settingsModal: SettingsModal;
	@ViewChild(DownloadLinkModal) downloadLinkModal: DownloadLinkModal;

	constructor(
		private router: Router,
		public globalSettingsService: GlobalSettingsService,
		private utils: Utils,
		private routerInterceptor: RouterInterceptorService,
		private eupRoutesService: EupRoutesService,
		private route: ActivatedRoute,
		private messagesService: MessagesService,
		private rxService: RxService,
		public orderService: OrdersService,
		private translateService: TranslateService,
		private eupObserver: EupObserver,
		private iteroLabService: IteroLabService,
		private accessibilityService: AccessibilityService,
		private authService: AuthService,
		private spinnerService: SpinnerService,
		localStorageService: LocalStorageService,
		private accountManagementService: AccountManagementService
	) {
		this.orderUpdatedEventSubscription = this.orderService.OrderUpdated.subscribe((order: Order) => {
			this.updateMembersByOrder(order);
		});
		this.storage = localStorageService.storage;
	}

	get windowsApplicationText(): string {
		this.route.queryParams.forEach((queryParams: Params) => {
			if (queryParams['winappdata']) {
				this.isOrthoCase = queryParams['isOrtho'] === 'true';
			}
		});
		return this.isOrthoCase
			? this.translateService.instant('Lab.OrthoCAD')
			: this.translateService.instant('Lab.iTeroLab');
	}

	get username(): string {
		return (this.settings) ? this.settings.username : '';
	}

	get disableSiteNavigationFunc(): boolean {
		return this.disableSiteNavigation === 'true';
	}

	get isBackLinkAbsoluteUrlFunc(): boolean {
		return this.isBackLinkAbsoluteUrl === 'true';
	}

	get shouldAddRoleToBackLinkFunc(): boolean {
		return this.shouldAddRoleToBackLink === 'true';
	}

	private get settings(): GlobalSettings {
		return this.globalSettingsService.get();
	}

	toggleLogoutMenu() {
		this.isLogoutOpen = !this.isLogoutOpen;
	}

	hideLogoutMenu() {
		this.isLogoutOpen = false;
	}

	toggleLinksMenu() {
		this.isLinksMenuOpen = !this.isLinksMenuOpen;
	}

	hideLinksMenu() {
		this.isLinksMenuOpen = false;
	}

	isIteroLab() {
		return !this.isOrthoCase;
	}

	onDownloadRequest(event) {
		this.downloadClicked.emit(event);
	}

	ngOnDestroy(): void {
		this.utils.clearObservablesSubscriptions(this);
		this.eupObserverSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		this.accountManagementService
			.getCompanyInfo(this.globalSettingsService.get().selectedCompanyId)
			.pipe(
				filter((response) => !!response),
				tap((result) => { this.hideAppButton = this.isOrthoCase === false && result.primaryAddressCountryId === CountryCode.China; }))
			.subscribe();

		this.orthoCadSetupDownloadUrl = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl;
		this.iTeroSetupDownloadUrl = this.eupRoutesService.downloads.iTeroSetupDownloadUrl;
		this.orthoCadSetupDownloadUrl_acs = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl_acs;
		this.iTeroSetupDownloadUrl_acs = this.eupRoutesService.downloads.iTeroSetupDownloadUrl_acs;

		this.rolePath = this.globalSettingsService.rolePath();
		if (!this.isBackLinkAbsoluteUrlFunc && this.shouldAddRoleToBackLinkFunc) {
			this.backLink = this.rolePath + '/' + this.backLink;
		}
		this.contextChangedSubscription = this.globalSettingsService.contextChanged.subscribe(
			(params: ContextParams) => {
				if (!params.companyId || !params.doctorId) {
					return;
				}
			}
		);

		// as currently supported only 1 company per personnel
		this.redirectIfRequired(this.settings.companies[0]);

		// when this property is set to true - override image and back link
		// according to previous url
		if (this.backToLastPage === 'true') {
			let previousRoute = this.routerInterceptor.previousRoute.split('?')[0].split('%')[0];
			if (previousRoute === '/') {
				previousRoute = previousRoute + this.backLink;
			}
			// if no previous url exists (e.g. user entered url in a new tab)
			// or the previous url is home so back button should link to home page
			// or user was redirected to a page from straight from login
			if (!previousRoute || previousRoute === '/login' || previousRoute.substring(0, previousRoute.indexOf('?')) === '/login') {
				this.setDefaultLinkAndImage();
			} else {
				// a previous url exists and it is not the home page so back button should link
				// to the last page that the user was at
				this.backLink = previousRoute.slice(1);
				this.image = this.backImage;
			}
		} else if (this.backToLastPage === 'false') {
			this.setDefaultLinkAndImage();
		}



		this.route.params.forEach((routeParams: Params) => {
			this.labCasesState = routeParams['labCasesState'];
		});

		this.route.queryParams.forEach((queryParams: Params) => {
			if (queryParams['winappdata']) {
				const isOrtho = queryParams['isOrtho'] === 'true',
					name = isOrtho
						? this.translateService.instant('Orders.OpenWithOrthoCAD')
						: this.translateService.instant('Orders.OpenWithiTero');

				this.calculateDownloadLink(isOrtho, queryParams['orderId']).subscribe((downloadLink) => {
					this.isOrthoCase = isOrtho;
					this.winAppData = new WindowApplicationLinkData(
						queryParams['winappdata'],
						name,
						downloadLink,
						queryParams['isOrtho'] === 'true'
							? WindowsApplicationProtocol.IteroLab
							: WindowsApplicationProtocol.OrthoCad
					);
				});
			}

			if (this.orderService.orderObservable$ !== undefined) {
				this.orderService.orderObservable$.subscribe((order: Order) => {
					this.updateMembersByOrder(order);
				});
			}
		});

		this.messagesService.getMessagesCount(this.settings.selectedCompanyId).subscribe((res: number) => {
			this.messagesCount = res;
		});

		const observer = {
			next: (value) => {
				switch (true) {
					case value.action === AT.WEB_VIEWER_COMPONENT_SAVE_ORDER_PROCEED_TO_MODELING:
					case value.action === AT.RESCAN_COMPONENT_ORDER_RESCAN_SUCCESS:
						this.disableDownloadScan = true;
						this.disableiTeroLab = true;
						break;
					default:
						break;
				}
			}
		};

		this.eupObserverSubscription = this.eupObserver.subscribe(observer);
	}

	private redirectIfRequired(company: Company): void {
		if (!company.handlingEupUrl) {
			return;
		}
		if (company.handlingEupUrl.endsWith('/')) {
			company.handlingEupUrl = company.handlingEupUrl.slice(0, -1);
		}

		const myIteroBffUrl = new URL(this.eupRoutesService.serverUrl);
		const companyMyIteroBffUrl = new URL(company.handlingEupUrl);
		const currentPath = this.accessibilityService.getPathName();
		const myiteroComUrl = new URL(
			`${this.accessibilityService.getLocation().origin}/login?returnUrl=${currentPath}`
		);

		const allowRedirection = myIteroBffUrl.host !== companyMyIteroBffUrl.host;
		if (allowRedirection) {
			this.authService.logout().subscribe(_ => {
				this.storage[Consts.Storage.RegionalApiEndpoint] = `${companyMyIteroBffUrl.origin}/`;
				this.accessibilityService.redirectTo(myiteroComUrl.href);
			});
		}
	}

	private calculateDownloadLink(isOrtho: boolean, orderId: string): Observable<string> {
		const oldLink = isOrtho ? this.orthoCadSetupDownloadUrl : this.iTeroSetupDownloadUrl;
		const newLink = isOrtho ? this.orthoCadSetupDownloadUrl_acs : this.iTeroSetupDownloadUrl_acs;

		if (!orderId || +orderId <= 0) {
			return of(oldLink);
		}

		const typesMap = {
			[StorageType.ACS]: newLink,
			[StorageType.OriginalFMS]: oldLink
		};

		return this.orderService.getFileStorage(+orderId)
			.pipe(
				map((storage) => typesMap[storage])
			);
	}

	logout(): void {
		this.authService.logout().subscribe();
	}

	updateMembersByOrder(order: Order) {
		if (order && this.showActionButtons) {
			this.disableActionButtons = false;
			this.disableDownloadScan = !order.canExportFile;
			this.disableiTeroLab = !order.canExportFile;
			this.lastBowId = order.lastBowId;
			this.lastDetailsId = order.lastDetailsId;
		}
	}

	onImageLinkClick(): void {
		if (this.uponHomeLinkClickCallback && this.homeLinkClickEvent) {
			if (!this.homeLinkClickSubscription) {
				this.spinnerService.start();
				this.homeLinkClickSubscription = this.homeLinkClickEvent.subscribe((res: any) => {
					const selectedCasesState = this.route.snapshot.queryParams.selectedCasesState;
					const queryParams = selectedCasesState ? { 'selectedCasesState': selectedCasesState } : {};
					if (this.labCasesState) {
						if (
							this.backLink.toLowerCase().indexOf('home') !== -1 &&
							this.backLink.toLowerCase().indexOf('q=') !== -1
						) {
							this.router.navigate([`/${this.backLink.toLowerCase()}`], { queryParams });
						} else {
							if (
								this.backLink.toLowerCase().indexOf('home') !== -1 &&
								this.backLink.toLowerCase().indexOf('labcasesstate') !== -1
							) {
								this.backLink = this.rolePath + '/' + LabStickyHeaderComponent.homeLink;
							}
							this.router.navigate([`/${this.backLink.toLowerCase()}`], {
								queryParams: { ...queryParams, labCasesState: this.labCasesState }
							});
						}
					} else {
						this.router.navigate([`/${this.backLink.toLowerCase()}`], { queryParams });
					}
					this.spinnerService.stop();
				});
			}
			this.uponHomeLinkClickCallback();
		} else {
			if (this.isBackLinkAbsoluteUrlFunc) {
				window.location.href = this.backLink;
			} else {
				if (this.labCasesState) {
					this.router.navigate([`/${this.backLink.toLowerCase()}`], {
						queryParams: { labCasesState: this.labCasesState }
					});
				} else {
					this.router.navigateByUrl(`/${this.backLink.toLowerCase()}`);
				}
			}
		}
	}

	onPrintClicked = () => this.rxService.printOrdersRx(this.orderId.toString()).pipe(take(1)).subscribe();

	onOpenWithiTeroLabClick = () => {
		if (this.iteroLabService.isNeedToSendToLab(this.shortOrderData)) {
			this.iteroLabService.prepareOpenApp(this.orderId, this.lastDetailsId).subscribe((url) => {
				this.onWindowsAppClick(url);
				this.orderService.updateLabOrderById(
					this.orderId.toString(),
					this.globalSettingsService.get().selectedDoctorId.toString(),
					this.globalSettingsService.get().selectedCompanyId.toString(),
					(order: Order) => {
						// optimistic UI issue
						order.canReturnToDoctor = false;
					}
				);
			});

		} else {
			this.onWindowsAppClick();
		}
	}

	onWindowsAppClick(link: string = null) {
		this.orderService.startProtocol(link ? link : this.winAppData.link, true);
		this.downloadLinkModal.show(this.winAppData.name, this.winAppData.downloadLink);
	}

	onExportClick = (): void => {
		const orderId = this.orderId + '';
		const doctorId = this.globalSettingsService.get().selectedDoctorId + '';
		const companyId = this.globalSettingsService.get().selectedCompanyId + '';

		this.orderService.getLabOrder({ orderId, doctorId, companyId }).subscribe((order) => {
			this.labExportModal.show([order]);
		});
	}

	private setDefaultLinkAndImage() {
		this.backLink = this.rolePath + '/' + LabStickyHeaderComponent.homeLink;
		this.image = LabStickyHeaderComponent.homeImage;
	}
}
