<header activity-monitor inactivity-timer>
    <div class="image-link-container">
        <a logClick id="link-back" class="image-link" ngClass="{{'svg-'+image+' svg-'+image+'-dims'}}" (click)="onImageLinkClick()"></a>
    </div>
    <span *ngIf="titleText" class="header-title">
        {{titleText}}
    </span>
    <div class="outside-content">
        <ng-content></ng-content>
    </div>
    <div class="download-component download-component-area">
        <div class="download-component-container">
            <eup-download-notification></eup-download-notification>
        </div>
    </div>
    <div *ngIf="showActionButtons === 'true'" class="action-buttons" [ngClass]="{'action-buttons-disabled': disableActionButtons}">
        <eup-button [id]="'itero-lab'"
            [hidden]="hideAppButton"
            class="itero-lab-btn"
            [buttonText]="windowsApplicationText"
            [clickHandler]="onOpenWithiTeroLabClick"
            [iconClass]="'svg-externalLinkAlt22 svg-externalLinkAlt22-dims'"
            [disabled]="disableiTeroLab"
        ></eup-button>

        <eup-button [id]="'print-rx'"
            class="print-rx-btn"
            [buttonText]="'Lab.PrintRx' | translate"
            [clickHandler]="onPrintClicked"
            [iconClass]="'svg-printRx22 svg-printRx22-dims'"
        ></eup-button>

        <eup-button [id]="'download-scans'"
            class="download-scans-btn"
            [ngClass]="{'download-scan-disabled': disableDownloadScan}"
            [buttonText]="'Lab.DownloadOneScan' | translate"
            [clickHandler]="onExportClick"
            [iconClass]="'svg-downloadScans22 svg-downloadScans22-dims'"
            [disabled]="disableDownloadScan">
        ></eup-button>
    </div>
    <div *ngIf="showAvatarContainer === 'true'" class="avatar-container" (clickOutside)="hideLogoutMenu()" >
        <div class="image-link-container">
            <a logClick id="logoutImage" class="image-link svg-avatar47 svg-avatar47-dims clickable" (click)="toggleLogoutMenu()"></a>
        </div>
        <div class="avatar-menu" [ngClass]="{'open':isLogoutOpen}">
            <p>{{username}}</p>
            <button logClick id="logoutButton" class="btn btn-primary" (click)="logout()">{{'StickyHeader.Logout' | translate }}</button>
        </div>
        <span>{{'StickyHeader.Logout' | translate}}</span>
    </div>

    <div *ngIf="showLinksContainer === 'true'" class="links-container">
        <div logClick id="menu" class="links-button clickable" (click)="toggleLinksMenu()" (clickOutside)="hideLinksMenu()">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
        </div>
        <ul class="list-inline links" [ngClass]="{'open':isLinksMenuOpen, 'disabled':disableSiteNavigationFunc}">
            <li>
                <a logClick id="messagesLink" class="image-link-container" [routerLink]="['/messages']" [queryParams]="{ isLabViewer: 'true' }">
                    <div class="image-link messages-logo">
						<span class="notification" [ngClass]="{ 'visible' : messagesCount > 0 }">
							{{messagesCount > 0 ? messagesCount : "" }}
						</span>
                        <svg class="svg-messages42 svg-messages42-dims"></svg>
                    </div>
                    <span>{{'Home.Messages' | translate}}</span>
                </a>
            </li>
            <li>
                <a logClick id="learningLink" class="image-link-container" [routerLink]="['/' + globalSettingsService.rolePath() + '/learningcenter']">
                    <div class="image-link svg-education52 svg-education52-dims education-logo"></div>
                    <span>{{ 'StickyHeader.Learning' | translate }}</span>
                </a>
            </li>
            <li [routerLink]="['/labs/settings']">
                <a logClick id="settingsLink" class="image-link-container">
                    <div class="image-link svg-settings45 svg-settings45-dims"></div>
                    <span>{{'StickyHeader.Settings' | translate}}</span>
                </a>
            </li>
        </ul>
    </div>
</header>
<eup-settings id="modal-settings"></eup-settings>
<eup-downloadLinkModal id="modal-download-link" linkTextKey="Orders.LinkText" disclaimer="Orders.Disclaimer" messageTextKey="Orders.MessageText"></eup-downloadLinkModal>
<eup-labExport (downloadClicked)="onDownloadRequest($event)" id="modal-lab-export"></eup-labExport>