<div class="die-orders">
    <div class="die-orders__order-line">
        <div class="adder-title">
            <div class="adder-title-header">
                {{('Lab.NoOfModels' | translate)}}
            </div>
            {{('Lab.IncludingDies' | translate)}}
        </div>
        <div class="adder-box">
            <item-adder [id]="'numOfModels'"
                (emittedNumber)="setModelOrders($event)"
                [disabled]="disabled"
                [fieldNumber]="numOfModels"
                [debouncePeriod]="300"
            ></item-adder>
        </div>
    </div>

    <div class="die-orders__order-line">
        <div class="adder-title">
            <div class="adder-title-header">
                {{('Lab.AdditionalDies' | translate)}}
            </div>
            {{ 'Lab.PerTooth' | translate }}
        </div>
        <div class="adder-box">
            <item-adder [id]="'additionalDies'"
                (emittedNumber)="setDieOrders($event)"
                [disabled]="disabled"
                [fieldNumber]="additionalDies"
                [debouncePeriod]="300"
            ></item-adder>
        </div>
    </div>
    <div class="die-orders__order-line">
        <tlk-checkbox
            id="dieDitch"
            logChange
            [isDisabled]="disabled"
            [checked]="dieDitch"
            (changeEvent)="setDieDitch($event)"
            class="die-orders__die-ditch-cb"
        >
            <label class="die-orders__die-ditch-label">{{('Lab.DieDitch' | translate)}}</label>
        </tlk-checkbox>
    </div>
    
</div>
