<div bsModal #exportSettingsModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <button logClick id="btn-hide-settings" type="button" class="close close-sign" (click)="exportSettingsModal.hide()">
                        <svg class="svg-backButton32 svg-backButton32-dims"></svg>
                    </button>
                    {{'Settings.Export_Settings' | translate }}
                </h3>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" *ngIf="allowOrthodonticsExport">
                    <h3>{{'Settings.Orthodontics' | translate }}</h3>

                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.ExportTypeiCast' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="exportTypeICast-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedExportTypeICastOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="exportTypeICast-selected" logClick [value]="option.value" *ngFor="let option of exportTypeICastOptions" (click)="setField('ExportTypeForiCast', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.ExportTypeiRecord' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="exportTypeIRecord-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedExportTypeIRecordOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="exportTypeIRecord-selected" logClick [value]="option.value" *ngFor="let option of exportTypeIRecordOptions" (click)="setField('ExportTypeForiRecord', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.DataFormat' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="dataFormat-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedDataFormatOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="dataFormat-selected" logClick [value]="option.value" *ngFor="let option of dataFormatOptions" (click)="setField('DataFormat', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.FileType' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="fileType-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedFileTypeOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="fileType-selected" logClick [value]="option.value" *ngFor="let option of fileTypeOptions" (click)="setField('FileType', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <br/>
                </form>

                <form class="form-horizontal" *ngIf="allowRestorativeExport">
                    <h3>{{'Settings.RESTORATIVE' | translate }}</h3>

                    <div class="form-group " [ngClass]="allowOrthodonticsExport === true ? 'eup-dropdown-last' : 'eup-dropdown'">
                        <label class="col-sm-6 control-label">{{'Settings.CAD_CAM_System' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="cadCamSystemTypes" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedCadCamSystemType?.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="cadCamSystemType-selected" logClick [value]="option.value" *ngFor="let option of cadCamSystemTypes" (click)="setField('ExportCadCamSystemType', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br/>
                </form>
                
                <form class="form-horizontal" *ngIf="!disablePatientDetailsBySWOpiont">
                    <h3>{{'Settings.General' | translate }}</h3>
                
                    <div class="checkbox-container">
                        <tlk-checkbox [id]="'exportSettings-hidePatientName-checkbox'"
                            logChange
                            [checked]="hidePatientName"
                            (changeEvent)="onHidePatientNameChanged($event.checked)">
                            {{'Settings.HidePatientName' | translate}}
                        </tlk-checkbox>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
