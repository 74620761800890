import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SettingsService } from '../settings.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { KeyValuePair } from '../../shared/generalInterfaces';
import { Subscription } from 'rxjs';
import { ExportRequest } from '../orthoCadExport/orthoCadExport.component';
import { DownloadNotificationService } from '../../shared/downloadNotification/downloadNotification.service';
import { DownloadFileInfo, ExportInfoDto, CreateRestorativeExportFileRequestDto } from '../downloadNotification/fileInfo.model';
import { ISettingsData } from '../../interfaces/ISettingsData';
import { RoleTypeEnum, SoftwareOptionsForCompany } from '../enums';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { BiObjectType } from '@logging/interfaces/bi.enums';

@Component({
	selector: 'eup-restorativeExport',
	templateUrl: './restorativeExport.component.html',
	styleUrls: [ './restorativeExport.component.scss' ]
})
export class RestorativeExportModal {
	@ViewChild('restorativeExportModal') restorativeExportModal: ModalDirective;

	caseTypeId: number;
	orderId: number;
	orderDetailsId: number;
	hidePatientName: boolean;
	disablePatientDetailsBySWOpiont: boolean;
	BiObjectType = BiObjectType; // Expose the enum to the template

	restorativeExportFileRequest: CreateRestorativeExportFileRequestDto = new CreateRestorativeExportFileRequestDto();
	cadCamSystemTypeOptions: KeyValuePair[] = null;
	selectedCadCamSystemType: KeyValuePair = new KeyValuePair('', '');

  exportSubscription: Subscription;
  exportWoSubscription: Subscription;

	constructor(
		private settingsService: SettingsService,
		private globalSettings: GlobalSettingsService,
		private downloadNotificationService: DownloadNotificationService,
		private softwareOptionsService: SoftwareOptionsService,
		private cdr: ChangeDetectorRef
	) {}

	show(inputCaseTypeId: string, request: ExportRequest): void {
		this.cdr.detectChanges();
		this.disablePatientDetailsBySWOpiont = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.shouldDisableHidePatientDetails, this.globalSettings.get().companySoftwareOptions);
		this.caseTypeId = +inputCaseTypeId;
		this.orderDetailsId = request.orderDetailsId;
		this.orderId = request.orderId;

		this.restorativeExportModal.show();

		this.settingsService.getSettings(this.globalSettings.get().selectedCompanyId, true, false).subscribe((settingsRes: ISettingsData) => {
			this.hidePatientName = settingsRes.userSettings.patientAnonymization;
			this.cadCamSystemTypeOptions = settingsRes.cadCamSystemTypes.filter((o) => +o.key > 0);
			this.selectedCadCamSystemType = new KeyValuePair('', '');
			if (settingsRes.userSettings.exportCadCamSystemType) {
				this.selectedCadCamSystemType = new KeyValuePair(
					settingsRes.userSettings.exportCadCamSystemType.toString(),
					settingsRes.userSettings.exportCadCamSystemType > 0
						? this.cadCamSystemTypeOptions.filter(
								(o) => o.key.toString() === settingsRes.userSettings.exportCadCamSystemType.toString()
							)[0].value
						: ''
				);
			}
		});

	}

  	export(): void {
		if (this.canExport()) {
			this.restorativeExportFileRequest.orderId = this.orderId;
			this.restorativeExportFileRequest.orderDetailsId = this.orderDetailsId;
			this.restorativeExportFileRequest.cadCamSystemTypeId = +this.selectedCadCamSystemType.key;

			let settings = this.globalSettings.get();
			var requestData = this.createRequestData(settings.contactId);

			if (settings.roleType == RoleTypeEnum.Lab) {
				// check if download file ready by ideOrder work order
				this.exportWoSubscription = this.downloadNotificationService
				.getRestorativeExportInfoByWorkOrder(requestData)
				.subscribe((res: ExportInfoDto) => {
					if (res && res.workOrderId) {
						const fileInfo: DownloadFileInfo = {
							workOrderId: res.workOrderId,
							orderId: requestData.orderId,
							orderDetailsId: requestData.orderDetailsId,
							downloaded: true,
							requestData: requestData
						};
						this.downloadNotificationService.downloadFile(fileInfo);
					} else {
						this.downloadNotificationService.addFile(requestData);
					}
				});
			} else {
				this.exportWoSubscription = this.downloadNotificationService
				.getRestorativeExportInfo(requestData)
				.subscribe((res: ExportInfoDto) => {
					if (res && res.isFileExists) {
						const fileInfo: DownloadFileInfo = {
							orderId: requestData.orderId,
							orderDetailsId: requestData.orderDetailsId,
							downloaded: true,
							requestData: requestData
						};
						this.downloadNotificationService.downloadFile(fileInfo);
					} else {
						this.downloadNotificationService.addFile(requestData);
					}
				});
			}
			
			this.restorativeExportModal.hide();
		}
	}

	createRequestData(clientId: number): CreateRestorativeExportFileRequestDto {
		var requestData = new CreateRestorativeExportFileRequestDto();
		requestData.clientId = clientId;
		requestData.orderId = this.restorativeExportFileRequest.orderId;
		requestData.orderDetailsId = this.restorativeExportFileRequest.orderDetailsId;
		requestData.cadCamSystemTypeId = this.restorativeExportFileRequest.cadCamSystemTypeId;
		requestData.patientAnonymization = this.disablePatientDetailsBySWOpiont ? false : this.hidePatientName;
		var today = new Date();
		requestData.creationTime = today.toUTCString();

		return requestData;
	}

	setField(fieldName: string, key: string, value: string): void {
		if (fieldName === 'ExportCadCamSystemType') {
			this.selectedCadCamSystemType.key = key;
			this.selectedCadCamSystemType.value = value;
		}
	}

	canExport(): boolean {
		return +this.selectedCadCamSystemType.key > 0;
	}

	onHidePatientNameChanged(newVal: boolean) {
		this.hidePatientName = newVal;
	}
}
