import { Injectable, OnDestroy } from '@angular/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { TimberService } from '@logging/timber.service';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { ClientDiscoveryService } from 'app/platform/services/client-discovery/client-discovery.service';
import { FeatureToggleSettings } from "../../featuresToggleSettings/featuresToggleSettings.model";
import { KnownComponentsNames } from '@core/components-names';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { EMPTY, from, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { FeatureToggle } from '@shared/enums';
import { Utils } from '@shared/utils.service';

export interface PendoContext {
	contactId: number,
	companyId: number,
	appName: string,
	featureFlags: FeatureToggleSettings[],
	companySoftwareOptions: string[],
}

@Injectable({
	providedIn: 'root',
})
export class PendoService implements OnDestroy {
	applicatioName = 'myItero.com';
	private readonly serviceName = 'PendoService';
	pendoSdk: any = null;
	initSubscription: Subscription;

	constructor(
		private readonly featuresToggleSettingsService: FeaturesToggleSettingsService,
		private readonly timber: TimberService,
		private readonly globalSettingService: GlobalSettingsService,
		private readonly shellContextService: ShellContextService,
		private readonly utilsService: Utils,
	) {}

	ngOnDestroy(): void {
		this.utilsService.clearObservablesSubscriptions(this);
	}

	logInfoFromPendoService = (message: string) => {
		this.timber.info(message, { module: this.serviceName });
	};

	async initializePendo(contactId, companyId) {
		const cswo = Object.keys(this.globalSettingService.get()?.companySoftwareOptionsKeyValuePair || {});

		const serviceData = await ClientDiscoveryService.getClientApplication(KnownComponentsNames.PendoSdk);
		if (serviceData) {
			this.addUrlToContext(serviceData.url ?? '');
			const pendoPromise = import(/* webpackIgnore: true */ serviceData?.url);

			const pendoSdk$ = from(pendoPromise).pipe(
				catchError((error) => {
					this.timber.error('Failed to load Pendo SDK', { module: this.serviceName, error });
					return EMPTY;
				}));

			this.initSubscription = this.featuresToggleSettingsService
				.getFeaturesToggleSettings()
				.pipe(
					filter((featureFlags: FeatureToggleSettings[]) =>
						featureFlags.some((ft) => ft.id === FeatureToggle.PendoToggle && ft.isActive)),
					switchMap((featureFlags) => pendoSdk$.pipe(map((pendoSdk) => [featureFlags, pendoSdk]))),
					tap(([featureFlags, pendoSdk]: [FeatureToggleSettings[], any]) => {
						if(!pendoSdk) {
							return;
						}
						this.pendoSdk = pendoSdk;
						const pendoContext = {
							contactId,
							companyId,
							appName: this.applicatioName,
							featureFlags,
							companySoftwareOptions: cswo,
						} as PendoContext;
						this.pendoSdk.manage(pendoContext);
					})
				)
				.subscribe();
		}
	}

	closePendoInstance() {
		if (this.pendoSdk) {
			this.pendoSdk.manage(undefined, (message: string) => console.log(message));
		}
	}

	addUrlToContext(url: string) {
		this.shellContextService.updateContext((ctx) => {
			if (!ctx.platformServices.some((x) => x.name == KnownComponentsNames.PendoSdk))
				ctx.platformServices.push({ name: KnownComponentsNames.PendoSdk, url: url });
		});
	}
}
