import { Directive, HostListener, Input } from '@angular/core';
import { BiFeatureWrapperService } from '@logging/bi-feature-wrapper.service';
import { BiAction, BiObjectType } from '@logging/interfaces/bi.enums';
import { TimberService } from '@logging/timber.service';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { firstValueFrom } from 'rxjs';

@Directive({
	selector: '[logChange]',
})
export class LogChangeDirective {
	@Input() objectType: BiObjectType = BiObjectType.Button;
	@Input() value!: string;

	constructor(
		private shellContextService: ShellContextService,
		private timberService: TimberService,
		private biFeatureWrapperService: BiFeatureWrapperService
	) {}

	@HostListener('change', ['$event'])
	async handleChange(event: MouseEvent) {
		Promise.resolve().then(async () => {
			const context = await firstValueFrom(this.shellContextService.getContext());

			const orderId = context?.bizCtx?.orderId;
			const selectedValue = this.value ? this.value : orderId ? `orderId: ${orderId.toString()}` : null;
			const changeElement = event.target as HTMLInputElement;

			this.timberService.userActionEvent({
				locationParentObject: this.biFeatureWrapperService.getCurrentPage(),
				objectName: this.getElementId(changeElement.id),
				objectType: this.getObjectType(changeElement),
				action: changeElement.checked ? BiAction.Checked : BiAction.UnChecked,
				selectedValue: selectedValue,
			});
		});
	}

	getElementId(id: string): string {
		if (id.lastIndexOf('-') > -1) {
			return id.substring(0, id.lastIndexOf('-'));
		}
		return id;
	}

	getObjectType(element: HTMLElement): BiObjectType {
		const tagName = element.tagName.toLowerCase();
		if(tagName === 'input') {
			const inputElement = element as HTMLInputElement;

			switch (inputElement.type) {
				case 'checkbox':
					return BiObjectType.Checkbox;
				case 'radio':
					return BiObjectType.RadioButton;
				default:
					return this.objectType;
			}
		} 

	}
}
