<div bsModal #rxSettingsModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <button logClick id="btn-hide-settings" type="button" class="close close-sign" (click)="rxSettingsModal.hide()">
                        <svg class="svg-backButton32 svg-backButton32-dims"></svg>
                    </button>
                    {{'Settings.Rx_Settings' | translate }}
                </h3>
            </div>
            <div class="modal-body">
                <form class="form-horizontal">
                    <div *ngIf="loading" class="col-sm-12 loading-label blink">{{'Settings.Loading' | translate }}</div>
                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.Tooth_ID' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="toothId-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{ toothIdStr }}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="toothId-selected" logClick [value]="rowData.value" *ngFor="let rowData of toothIdListOptions" (click)="setField('ToothId', rowData.key, rowData.value)">{{rowData.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.Shade_System' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="shadeSystem-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{ shadeSystemStr }}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="shadeSystem-selected" logClick [value]="rowData.value" *ngFor="let rowData of shadeSystemListOptions" (click)="setField('ShadeSystemId', rowData.key, rowData.value)">{{rowData.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-group eup-dropdown" *ngIf="isProcedureBasedRx$ | async; else caseTypeFlowRx">
                        <label class="col-sm-6 control-label">{{'Settings.Procedure' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="procedures-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{ procedureStrSelected }}<span class="caret"></span></a>
                                <ul class="dropdown-menu procedure-dropdown-menu" *dropdownMenu>
                                    <li id="procedure-selected" logClick [value]="rowData.value" *ngFor="let rowData of availableProceduresListOptions" (click)="setField('DefaultProcedureId', rowData.key, rowData.value)">{{rowData.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ng-template #caseTypeFlowRx>
                        <div class="form-group eup-dropdown">
                            <label class="col-sm-6 control-label">{{'Settings.Case_Type' | translate }}</label>
                            <div class="col-sm-6">
                                <div dropdown class="dropdown-container">
                                    <a id="caseTypes-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{ userSettingsCaseTypeStr }}<span class="caret"></span></a>
                                    <ul class="dropdown-menu case-type-dropdown-menu" *dropdownMenu>
                                        <li id="caseType-selected" logClick [value]="rowData.value" *ngFor="let rowData of availableCaseTypesListOptions" (click)="setField('CaseTypeId', rowData.key, rowData.value)">{{rowData.value}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div class="form-group toggle-row" *ngIf="showNiriEnabledDefaultButton">
                        <div class="col-sm-6">{{'Settings.Niri_Captures' | translate }}</div>
                        <div class="col-sm-6 toggle" [ngClass]="{'toggle-disabled':loading}">
                            <tlk-slide-toggle 
                                logChange
                                [id]="'niri-toggle'"
                                [checked]="niriEnabledDefault"
                                (changeEvent)="onNiriEnabledDefaultChange($event)"
                            >
                                <span *ngIf="niriEnabledDefault" class="enabled-text">{{'Settings.Enabled' | translate }}</span>
                                <span *ngIf="!niriEnabledDefault" class="disbled-text">{{'Settings.Disabled' | translate }}</span>
                            </tlk-slide-toggle>
                        </div>
                    </div>
                </form>	
            </div>
        </div>
    </div>
</div>