<div bsModal #orthoCadExportModal="bs-modal" class="eup-modal modal fade top" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Rx.ExportType' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="exportType-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedExportTypeOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="exportType-selected" logClick [value]="option.value" *ngFor="let option of exportTypeOptions" (click)="setField('ExportType', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Rx.DataFormat' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="dataFormat-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedDataFormatOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="dataFormat-selected" logClick [value]="option.value" *ngFor="let option of dataFormatOptions" (click)="setField('DataFormat', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Rx.FileType' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="fileType-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedFileTypeOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="fileType-selected" logClick [value]="option.value" *ngFor="let option of fileTypeOptions" (click)="setField('FileType', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!disablePatientDetailsBySWOpiont">
                        <tlk-checkbox [id]="'orthoExport-hidePatientName-checkbox'"
                            logChange
                            [checked]="hidePatientName"
                            [isDisabled]="hidePatientName === undefined"
                            (changeEvent)="onHidePatientNameChanged($event.checked)">
                            {{'Settings.HidePatientName' | translate }}
                        </tlk-checkbox>
                    </div>

                    <div class="text-right">
                        <button logClick id="btn-cancel" type="button" class="btn btn-default orthoCadExport-btn" (click)="orthoCadExportModal.hide()">
                            {{'Rx.Cancel' | translate }}
                        </button>

                        <button logClick id="btn-export" type="button" class="btn btn-primary orthoCadExport-btn" (click)="export()" [disabled]="!canExport()">
                            {{'Rx.Export' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>