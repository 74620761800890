<div bsModal #labExportModal="bs-modal" class="eup-modal modal fade lab-export" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">

                <div class="export-header">
                    <h2>
                        <span class="export-title">{{'Settings.DownloadSettings' | translate }}</span>
                        <span class="export-label label-default">{{numOfOrders}} {{ 'Settings.ScanS' | translate }}</span>
                        <span logClick id="hide-btn" class="export-close" (click)="hide()">x</span>
                    </h2>
                </div>

                <ul class="nav nav-tabs">
                    <li logClick id="restorative-tab" [ngClass]="{'active': tab === 1}" *ngIf="numOfRestorativeCases > 0" (click)="openTab(1)">
                        <a>{{'Settings.RestorativeCase' | translate }} ({{numOfRestorativeCases}})</a>
                    </li>
                    <li logClick id="orthodontic-tab" [ngClass]="{'active': tab === 2}" *ngIf="numOfOrthodonticsCases > 0" (click)="openTab(2)">
                        <a>{{'Settings.OrthodonticCase' | translate }} ({{numOfOrthodonticsCases}})</a>
                    </li>
                </ul>
                <div class="tabs-content-container">
                    <form *ngIf="tab === 1">
                        <eup-generic-dropdown [id]="'cadCamSystem'"
                            label="{{'Settings.CAD_CAM_System' | translate }}"
                            [items]="cadCamSystemTypes"
                            [selectedValue]="exportSettings.cadCamSystemTypeId"
                            (onSelect)="onSelectCadCamSystemTypes($event)">
                        </eup-generic-dropdown>
                    </form>

                    <form *ngIf="tab === 2">
                        <eup-generic-dropdown [id]="'exportTypeIrecord'"
                            label="{{'Settings.ExportTypeiRecord' | translate }}"
                            [items]="exportTypeIrecordOptions"
                            [selectedValue]="exportSettings.exportTypeIrecord"
                            (onSelect)="onSelectExportTypeIrecord($event)">
                        </eup-generic-dropdown>

                        <eup-generic-dropdown [id]="'dataFormat'"
                            label="{{'Rx.DataFormat' | translate }}"
                            [items]="dataFormatOptions"
                            [selectedValue]="exportSettings.dataFormat"
                            (onSelect)="onSelectDataFormat($event)">
                        </eup-generic-dropdown>

                        <eup-generic-dropdown [id]="'fileType'"
                            label="{{'Rx.FileType' | translate }}"
                            [items]="fileTypeOptions"
                            [selectedValue]="exportSettings.fileType"
                            (onSelect)="onSelectFileTypeOptions($event)">
                        </eup-generic-dropdown>
                    </form>

                    <div class="lab-export__set-default-container" *ngIf="!defaultValuesAreSet">
                        <tlk-checkbox [id]="'lab-export-set-default-container'"
                            logChange
                            [checked]="shouldSaveAsDefaultValues"
                            [isDisabled]="false"
                            (changeEvent)="saveAsDefaultValues($event.checked)">
                            {{'Rx.SetAsDefault' | translate }}
                        </tlk-checkbox>
                    </div>
                </div>

                <hr class="horizontal-separator"/>

                <div class="lab-export__hide-patient-name-container" *ngIf="!disablePatientDetailsBySWOpiont && hidePatientName !== undefined">
                    <tlk-checkbox [id]="'lab-export-hide-patient-name-checkbox'"
                        logChange
                        [checked]="hidePatientName"
                        [isDisabled]="false"
                        (changeEvent)="setHidePatientName($event.checked)">
                        {{'Settings.HidePatientName' | translate }}
                    </tlk-checkbox>
                </div>

                <div class="text-right export-footer">
                    <button logClick id="btn-cancel" type="button" class="cancel-btn" (click)="hide()">
                        {{'Rx.Cancel' | translate }}
                    </button>

                    <eup-button [id]="'export'"
                        [buttonText]="'Settings.Download' | translate"
                        [clickHandler]="export"
                        [iconClass]="'svg-downloadScans22 svg-downloadScans22-dims'">
                    </eup-button>
                </div>

            </div>
        </div>
    </div>
</div>
