<div bsModal #restorativeExportModal="bs-modal" class="eup-modal modal fade top" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.CAD_CAM_System' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a id="cadCamSystem-options" logClick [objectType]="BiObjectType.Combobox" dropdownToggle>{{selectedCadCamSystemType.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li id="cadCamSystem-selected" logClick [value]="option.value" *ngFor="let option of cadCamSystemTypeOptions" (click)="setField('ExportCadCamSystemType', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!disablePatientDetailsBySWOpiont">
                        <tlk-checkbox [id]="'restoExport-hidePatientName-checkbox'"
                            logChange
                            [checked]="hidePatientName"
                            [isDisabled]="hidePatientName === undefined"
                            (changeEvent)="onHidePatientNameChanged($event.checked)">
                            {{'Settings.HidePatientName' | translate }}
                        </tlk-checkbox>
                    </div>

                    <div class="text-right">
                        <button logClick id="btn-cancel" type="button" class="btn btn-default restorativeExport-btn" (click)="restorativeExportModal.hide()">
                            {{'Rx.Cancel' | translate }}
                        </button>

                        <button logClick id="btn-export" type="button" class="btn btn-primary restorativeExport-btn" (click)="export()" [disabled]="!canExport()">
                            {{'Rx.Export' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>